import React from 'react'
import Link from 'gatsby-link'
import ScanningContainer from '../../components/ScanningContainer'
import CrossImpact from '../../components/CrossImpact'
import Drivers from '../../components/Drivers'
import HeaderMain from '../../components/Header'
import { Header, Icon, List, Divider, Item } from 'semantic-ui-react'
import './style.css'

class Futures extends React.Component {
    state = {
        drivers: []
    }

    componentDidMount() {
        this.getDrivers();
    }

    getDrivers() {
        fetch("https://api.airtable.com/v0/app1POYiufgjnmVCa/Drivers?maxRecords=10&view=Grid%20view", {
         headers: {"Authorization": "Bearer keyN4bQqzj0O0fVbT"}
       }).then((response) => response.json())
           .then((responseData) => {
               const drivers = responseData.records
               this.setState({drivers}, () => {
                   console.log(this.state.drivers);
               })
         })
    }


    render () {
        const allHits = this.state.allHits;
        return (

            <div className="baseline-page page-wrapper">
            <HeaderMain />
                {/* // <div className="section-content">
                //     <h2> The Future of Death in  America</h2>
                //     <h3 className="bl-subtitle"> How we live on through our death, disposal, and the things that we leave behind.</h3>
                //     <Divider />
                // </div> */}
                <div className="summary-wrapper">
                    <div className="section-content">
                        <Header textAlign="center" className="section-header">Baseline Summary</Header>
                        <div className="bl-narrative">
                            <p>
                                Technology will reshape death in multiple ways; by how individuals plan their deaths, by providing more transparency in an industry previously dominated by predatory practices, by providing us new ways to be memorialized, and ultimately even by providing us a means to live forever. Cremation is surpassing and will soon dominate how we choose to dispose of our bodies in the United States. And as the green movement continues and more and more Americans don’t explicitly identify as religious even those who do choose to be buried are going green. These trends coupled with the rise in technology have the potential to create new ways to both dispose or store our bodies. But religion and the funeral industry have held a tight grip on laws regulating what we can do with our bodies when we die and the changing of those laws has been slow. Ultimately, people are looking for change and the $25 billion dollar a year death industry is ripe for major disruption.
                            </p>
                            {/* <p>
                                Ultimately, technology will give us the ability to create a perfect recreation of our mind, complete with our memories and the ability to learn and synthesize new ideas. It will be gradual, at first we will be able to converse digitally with an avatar of our loved ones, it will be comforting and help with the grieving process. But as the technology becomes more concrete, and the avatars grow closer to the real world, we will have to start asking ourselves some new questions around what it means to be mortal, what makes us humans, and who we really are.
                            </p> */}
                        </div>
                        {/* <Divider /> */}
                    </div>
                </div>

                <div className="summary-wrapper transformative">
                    <Link className="future-scenario-section-link" to="/futures/Transformative">
                    <div className="section-content">
                            <Header textAlign="center" className="section-header">Transformation Summary</Header>
                            <div className="bl-narrative">
                                <p>
                                    Increased stress and depression create a suicide epidemic that in turn causes the United States to confront our fear of death head on. Our death rituals go from the somber remembrance of one individual to a communal celebration of our return to nature. Green techno-death parks are created that replace traditional funeral homes. These high-tech, but completely sustainable parks serve as places for cremation, composting, as well as being state of the art meditative visitation centers. Advances an A.I. and software as a service helps us in the grieving process by offering us instant virtual videos, and messages from our loved ones, as well as our first look at the artificial afterlife with A.I.’s capable of replicating much of the speech and thought processes of our loved ones. If you thought Google Assistant was cool, imagine if you could just say, “Ok Dad...”
                                </p>
                                <h4>Differentiators</h4>
                                <p>Suicide rates have been rising fast for the past 20 years. Any increase in velocity could create an epidemic that would cause us to confront death at a much earlier age and on a much more regular basis. This potentially creates a ripple effect with implications across all of our STEEP categories. Because we no longer ignore death we prepare. And because we acknowledge that we will spend longer as part of the earth than as a human who walks on it, we generally give our bodies back to it in the greenest way possible, composting. Our funerals aren't about us as individuals, they are about us as a group returning to be a part of everything. Our technology follows this mission, it serves to help us remember the deceased by giving us access to them anywhere.</p>
                            </div>
                            <p className="click-to-adv">click to view full scenario narrative</p>
                            {/* <Divider /> */}
                    </div>
                    </Link>
                </div>
                {/* <Header textAlign="center" className="section-header">Cross Impact Analysis of Important Trends</Header>
                <CrossImpact />
                <h4>Cross Impact Analysis Summary</h4>
                <p className="bl-narrative">Like in Britain where studies have shown a rise in cremation rate were tied to a decrease in an individual's likelihood to explicitly identify as religious, we can see in this analysis how the impact of religion on death has had a profound underlying impact on changes across a majority of the trends studied through its effect on our laws and regulations. Looking at the laws and regulations row we can see it as an elastic band holding innovation down across many of the other trends. If we can reverse or change the outdated laws around death in the United States we will see a tremendous impact with regards to an increase in disposal methods, lower funeral costs, and better use of technology throughout the death and grieving process. On the flipside, we can the high cost of funerals is both supporting the laws and regulations through funeral lobby groups while also forcing people to look for new ways to affordably deal with the dead. </p>*/}
            </div>
        )
    }
}


export default Futures
